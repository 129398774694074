<template>
  <div class="news">
    <div class="container">
      <div class="news__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}</locale-router-link
        >
        <a class="crumbs-link page-catalog-link">{{ $t("news.title") }}</a>
      </div>
      <h1 class="news__title">{{ $t("news.title") }}</h1>
      <news-list :items="newsData"/>
    </div>
  </div>
</template>

<script>
import { getArticles } from "@/api/articles"

import NewsList from "@/components/news/NewsList.vue"

export default {
  name: "PageNews",

  components: {
    NewsList,
  },

  data() {
    return {
      newsData: [],
    }
  },

  mounted() {
    this.loadAllNews()
  },

  methods: {
    async loadAllNews() {
      const response = await getArticles(1, 500, "news")
      this.newsData = response.data
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
