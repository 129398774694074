<template>
  <div class="news-list">
    <news-card v-for="item in items" :key="item.id" :item="item"/>
  </div>
</template>

<script>
import NewsCard from "@/components/news/NewsCard.vue"

export default {
  name: "NewsList",

  components: {
    NewsCard,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
