<template>
  <div class="news-card">
    <locale-router-link :to="`news/${item.id}`">
      <img
        class="news-card__img"
        :src="getNewsImg()"
        alt=""
      />
      <p class="news-card__date" v-html="getDate()"></p>
      <p class="news-card__title">{{item.attributes.header}}</p>
    </locale-router-link>
  </div>
</template>

<script>
export default {
  name: "NewsCard",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localMonth: {
        января: "қаңтар",
        февраля: "ақпан",
        марта: "наурыз",
        апреля: "сәуір",
        мая: "мамыр",
        июня: "маусым",
        июля: "шілде",
        августа: "тамыз",
        сентября: "қыркүйек",
        октября: "қазан",
        ноября: "қараша",
      },
    }
  },

  methods: {
    getNewsImg() {
      const imgUrl = this.item?.attributes?.image?.data?.attributes?.url
      if (imgUrl) {
        return process.env.VUE_APP_API_URL + imgUrl
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },

    getDate() {
      const dateFromStr = new Date(this.item.attributes.publishedAt)
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr)
      let date = dateRU.split(" ")
      let dateKZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2]
      if (this.$i18n.locale === "kz") {
        return dateKZ
      } else {
        return dateRU
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
></style>
